import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PartnerService } from '@services/feature-service/partner.service';

@Component({
  standalone: true,
  imports: [NgClass, TranslateModule],
  selector: 'app-calio-footer',
  templateUrl: './calio-footer.component.html'
})
export class CalioFooterComponent implements OnInit {

  private partnerService = inject(PartnerService);

  protected showFooter = false;
  private partnerLoadEvent$: any;
  protected currYear = new Date().getFullYear();

  ngOnInit() {
    this.partnerLoadEvent$ = this.partnerService.partnerLoadEvent.subscribe(partner => {
      if (partner?.uuid && partner?.has_active_subscription) {
        this.showFooter = !this.partnerService.isWhitelabeledPartner;
      }
      this.partnerLoadEvent$.unsubscribe();
    });
  }
}
