<app-calio-header />

<!-- Ghost element -->
@if (false) {
  <section>
    <!-- Partner background image -->
    <div class="cbp-animate-pulse cbp-w-full cbp-h-[200px] cbp-bg-calenso-skeleton-bg-color max-sm:cbp-hidden cbp-relative cbp-z-0"></div>
    <!-- Partner background image -->
    <!-- Partner details -->
    <div class="cbp-container cbp-mx-auto cbp--mt-[30px] cbp-bg-calenso-white-color cbp-border cbp-border-calenso-hawkes-blue-color cbp-relative cbp-z-10 cbp-rounded max-sm:cbp-mt-0 max-sm:cbp-border-none">
      <hr class="cbp-my-1 sm:cbp-hidden" />
      <div class="cbp-p-[30px] cbp-relative cbp-z-10 max-md:cbp-p-3.5">
        <div class="cbp-flex max-md:cbp-flex-col">
          <!-- Partner logo -->
          <div class="cbp-shrink-0">
            <div class="cbp-animate-pulse cbp-h-[150px] cbp-w-[150px] cbp-rounded cbp-bg-calenso-skeleton-bg-color max-sm:cbp-hidden"></div>
          </div>
          <!-- Partner logo -->
          <!-- contact information -->
          <div class="cbp-ml-[30px] cbp-w-full max-md:cbp-ml-0 max-md:cbp-mt-2">
            <div class="cbp-animate-pulse cbp-h-8 cbp-w-40 cbp-rounded cbp-bg-calenso-skeleton-bg-color"></div>
            <div class="cbp-animate-pulse cbp-h-24 cbp-w-full cbp-bg-calenso-skeleton-bg-color cbp-rounded cbp-mt-5"></div>
          </div>
          <!-- contact information -->
        </div>
      </div>
      <hr class="cbp-my-3 sm:cbp-hidden" />
      <div class="max-md:cbp-p-3.5 cbp-relative cbp-z-10 cbp-w-full sm:cbp-hidden">
        <div class="cbp-animate-pulse cbp-h-96 cbp-w-full cbp-bg-calenso-skeleton-bg-color cbp-rounded"></div>
      </div>
    </div>
    <!-- Partner details -->
  </section>
}
<!-- Ghost element -->

@if (showPartnerError || showNoServiceEventsActiveError || showSubscriptionMissingError) {
  <app-calio-card>
    <ng-container card-body>
      <div class="cbp-container cbp-mx-auto cbp-mt-3 cbp-p-[30px]">
        @if (showPartnerError) {
          <app-calio-alert
            type="cbp-danger"
            title="error_messages.invalid_partner_title"
            subTitle="error_messages.invalid_partner_message"
          />
        }
        @if (showSubscriptionMissingError) {
          <app-calio-alert
            type="cbp-danger"
            title="error_messages.subscription_missing_title"
            subTitle="error_messages.subscription_missing_message"
          />
        }
        @if (showNoServiceEventsActiveError) {
          <app-calio-alert
            type="cbp-danger"
            title="error_messages.configuration_error_title"
            subTitle="error_messages.configuration_error_message"
          />
        }
      </div>
    </ng-container>
  </app-calio-card>
} @else {
  <router-outlet />
}
<app-calio-footer />
