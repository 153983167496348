
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CalioAlertComponent } from '@components/calio-alert/calio-alert.component';
import { CalioCardComponent } from '@components/calio-card/calio-card.component';
import { LS_KEYS, WIDGET_APPOINTMENT_TYPE } from '@constants/app.constants';
import { PartnerDbModel } from '@data-models/partner-db.model';
import { CalioFooterComponent } from '@layout/calio-footer/calio-footer.component';
import { CalioHeaderComponent } from '@layout/calio-header/calio-header.component';
import { PartnerService } from '@services/feature-service/partner.service';
import { HelperService } from '@services/helper/helper.service';
import { CryptoService } from '@services/util-service/crypto.service';
import { DomManipulationService } from '@services/util-service/dom-manipulation.service';
import { LocalStorageService } from '@services/util-service/local-storage.service';
import { LoggerService } from '@services/util-service/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, CalioHeaderComponent, CalioCardComponent, CalioAlertComponent, CalioFooterComponent]
})
export class AppComponent {

  private partnerService = inject(PartnerService);
  private helperService = inject(HelperService);
  private domManipulationService = inject(DomManipulationService);
  private localStorageService = inject(LocalStorageService);
  private cryptoService = inject(CryptoService);

  private readonly lsKeys = LS_KEYS;
  private partnerLoadEvent$: any;
  protected showGhostElement = true;
  protected showNoServiceEventsActiveError = false;
  protected showPartnerError = false;
  protected showSubscriptionMissingError = false;

  constructor() {
    this.helperService.registerSvgIcons();
  }

  ngOnInit(): void {
    const queryParams = this.helperService.getWidgetQueryParams();
    this.partnerLoadEvent$ = this.partnerService.partnerLoadEvent.subscribe(partner => {

      // Storing partner data in local to make it accessable for widget
      this.storePartnerDataInLocalstorage(partner);

      if (this.partnerService.isWhitelabeledPartner) {
        this.updateCssVariables(partner);
      }

      this.showGhostElement = false;
      this.partnerLoadEvent$.unsubscribe();
      if (!partner?.has_active_subscription) {
        this.showSubscriptionMissingError = true;
      } else if (!partner?.uuid) {
        this.showPartnerError = true;
      } else if (!partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_GROUP_APPOINTMENT) && !partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_APPOINTMENT)) {
        this.showNoServiceEventsActiveError = true;
      } else if ((queryParams['event-id'] !== undefined || queryParams['event_id'] !== undefined) && !partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_GROUP_APPOINTMENT)) {
        this.showNoServiceEventsActiveError = true;
      }

      if (partner?.booking_page_background_color) {
        this.domManipulationService.setStyleToBodyTag('background', partner.booking_page_background_color);
      }

      // Removing fix to redirect to event widget automatically when appointment booking is disabled and event is enabled
      // if (partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_GROUP_APPOINTMENT) && !partner?.widget_type_preferences?.includes(WIDGET_APPOINTMENT_TYPE.EXTERNAL_APPOINTMENT)) {
      //   this.router.navigate(['/' + partner.booking_name, 'events']);
      // }
    });

    this.partnerService.partnerCssOverwritesEvent.subscribe(cssOverwrite => {
      if (cssOverwrite) {
        if (this.partnerService.partner.id !== cssOverwrite.partner_id) {
          LoggerService.warn('Partner id is not matching for apply pre-define css-overwrite');
        } else if (!cssOverwrite.is_active) {
          LoggerService.warn('css-overwrite is not active');
        } else if (cssOverwrite.type !== 'booking_page') {
          LoggerService.error('Invalid css-overwrite type');
        } else {
          LoggerService.log('[css-overwrite]', 'applying css-overwrite provided in url parameter');
          this.domManipulationService.applyCssOverwrite(cssOverwrite.code);
        }
      }
    });
  }

  private storePartnerDataInLocalstorage(partner: PartnerDbModel): void {
    const encryptedPartnerObject = this.cryptoService.encryptValue(JSON.stringify(partner));
    this.localStorageService.set(this.lsKeys.PARTNER_OBJECT + partner.booking_name, encryptedPartnerObject, false);
  }

  private updateCssVariables(partner: PartnerDbModel): void {
    if (partner?.booking_page_box_shadow) {
      this.domManipulationService.updateCssVariable('--calenso-box-shadow', partner.booking_page_box_shadow);
    }
    if (partner?.booking_page_border_radius) {
      this.domManipulationService.updateCssVariable('--calenso-border-radius', partner.booking_page_border_radius);
    }
    if (partner?.booking_page_background_color) {
      this.domManipulationService.updateCssVariable('--calenso-body-background-color', partner.booking_page_background_color);
    }
    if (partner?.booking_page_primary_color) {
      this.domManipulationService.updateCssVariable('--calenso-font-color', partner.booking_page_primary_color);
    }
    if (partner?.landingpage_header_background_color) {
      this.domManipulationService.updateCssVariable('--calenso-header-background-color', partner.landingpage_header_background_color);
    }
    if (partner?.landingpage_tab_content) {
      this.domManipulationService.updateCssVariable('--calenso-card-background-color', partner.landingpage_tab_content);
    }
  }
}
