import { NgOptimizedImage, TitleCasePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LANGUAGES } from "@constants/app.constants";
import { PartnerDbModel } from '@data-models/partner-db.model';
import { ImgFallbackDirective } from '@directives/img-fallback.directive';
import { environment } from '@env/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrokenImageHandlerPipe } from '@pipes/broken-image-handler.pipe';
import { PartnerService } from '@services/feature-service/partner.service';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'app-calio-header',
  templateUrl: './calio-header.component.html',
  standalone: true,
  imports: [RouterModule, MatMenuModule, MatButtonModule, TranslateModule, TitleCasePipe, NgOptimizedImage, ImgFallbackDirective, BrokenImageHandlerPipe]
})
export class CalioHeaderComponent implements OnInit {

  readonly deployUrl = environment.deployUrl;
  readonly landingPageImagePath = environment.landingPageImagePath;
  readonly partnersImagePath = environment.partnersImagePath;
  readonly languages = LANGUAGES;

  private partnerService = inject(PartnerService);
  private translate = inject(TranslateService);
  private helperService = inject(HelperService);
  protected partnerLoadEvent$: any;

  protected supportedLanguages: string[] = [];
  protected lang: string;
  protected partner: PartnerDbModel;
  protected logo: string;
  protected internal = false;
  protected isIframe = false;

  ngOnInit() {
    this.isIframe = this.helperService.isIframe();

    this.partnerLoadEvent$ = this.partnerService.partnerLoadEvent.subscribe(partner => {
      if (partner?.uuid && partner?.has_active_subscription) {
        this.partner = partner;
        this.internal = this.partnerService.isInternal;
        this.supportedLanguages = this.partner.supported_widget_languages;

        if (this.partnerService.isWhitelabeledPartner) {
          if (this.partner?.landingpage_logo) {
            this.logo = `${this.landingPageImagePath}${this.partner.uuid}/${this.partner.landingpage_logo}`;
          } else if (this.partner?.logo) {
            this.logo = `${this.partnersImagePath}${this.partner.uuid}/${this.partner.logo}`;
          }
        }
      } else {
        this.supportedLanguages = [this.helperService.getDefaultLanguage()];
      }

      if (!this.logo) {
        this.logo = '/images/logo/calenso_logo_new.svg';
      }

      this.lang = this.translate.getDefaultLang();
      this.partnerLoadEvent$.unsubscribe();
    });
  }

  switchLanguage(language: string): void {
    this.lang = language;
    this.translate.use(language);
    this.translate.setDefaultLang(language);
  }
}
