<nav id="header" class="cbp-flex cbp-items-center cbp-bg-calenso-header-background-color cbp-h-[60px] max-md:cbp-px-3 max-md:cbp-h-[50px]">
  <div class="cbp-container cbp-mx-auto cbp-flex cbp-items-center cbp-justify-between">
    <div class="cbp-flex cbp-items-center cbp-flex-shrink-0 cbp-text-white cbp-mr-6 cbpc-partner-logo">
      @if (logo) {
        <a [routerLink]="">
          <img
            appImgFallback="/images/logo/calenso_logo_new.svg"
            rel="preload"
            fetchPriority="high"
            class="cbp-h-10"
            height="40"
            src="{{ logo }}"
            attr.alt="{{ partner?.name || undefined }}"
            attr.title="{{ partner?.name || undefined }}"
          />
        </a>
      }
    </div>


    @if (!internal && !isIframe) {
      @if (languages) {
        @if (supportedLanguages?.length > 1) {
          <div class="cbpc-partner-languages">
            <button [matMenuTriggerFor]="menu" class="cbcp-selected-language" aria-label="Example icon-button with a menu">
              <span>
                @for (language of languages; track language) {
                  @if (lang === language.locale) {
                    <img
                      priority
                      class="flag-icon"
                      height="20"
                      width="20"
                      [ngSrc]="(deployUrl + language.flagIcon) | brokenImageHandler"
                      alt="{{ language.text | titlecase }}"
                      title="{{ language.text | titlecase }}"
                    />
                  }
                }
              </span>
            </button>
            <mat-menu class="cbp-mt-3 !cbp-min-w-[50px] cbcp-language-list" #menu="matMenu" xPosition="before">
              @for (language of languages; track language) {
                @if (lang !== language.locale && supportedLanguages && supportedLanguages.indexOf(language.value) > -1) {
                  <a
                    mat-menu-item
                    [routerLink]=""
                    (click)="switchLanguage(language.locale)"
                  >
                    <img
                      class="cbp-w-5"
                      height="20"
                      width="20"
                      [ngSrc]="(deployUrl + language.flagIcon) | brokenImageHandler"
                      alt="{{ language.text | titlecase }}"
                      title="{{ language.text | titlecase }}"
                      (click)="switchLanguage(language.locale)"
                    />
                  </a>
                }
              }
            </mat-menu>
          </div>
        }
      }
    }
  </div>
</nav>
